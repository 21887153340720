@font-face {
  font-family: "NaikaiFont";
  src: url("../public/font/NaikaiFont.woff2") format("woff");
}

body {
  background-color: #1e1e1e;
  color: #ffffff;
  margin: 0;
  padding-left: 0;
  font-family: "NaikaiFont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (orientation: landscape) {
/*開始介面*/
.StartNameBox{
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  background-position: center;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 11;
}

.StartNameBox .inputBg{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35vw;
  height: 40vh;
  background-color: #9480b2;
}

.StartNameBox .inputBox{
  width: 18vw;
  height: 6vh;
  border: black solid 5px;
  margin: 10vh 0 5vh 0;
  padding-left: 1vw;
  color: #000;
  font-size: 2.4vh;
}

.StartNameBox .inputBox::placeholder{
  color: #999;
  font-size: 2.4vh;
}

.StartNameBox .inputButton{
  width: 18vw;
  height: 6vh;
  font-size: 4vh;
  font-weight: bold;
  color: #000;
  background-color: rgba(0,0,0,0);
  border: none;
}

.StartScene {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  z-index: 10;
}

.StartScene .blank{
  background-position: center;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.StartScene .bg{
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 0;
}

.StartScene .chara{
  position: absolute;
  width: 41.5%;
  top: 0;
  right: 0;
  z-index: 5;
}

.trioptions{
  position: absolute;
  width: 35vw;
  height: auto;
  top: 11vh;
  left: 20vw;
  z-index: 10;
  list-style-type: none;
  padding: 0vh 3vw;
}

.trioptions button{
  display: flex;
  position: relative;
  border: 0;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 30vw;
  margin-bottom: 6vh;
  background: 0;
}

.trioptions button:hover{
  cursor: pointer;
  transform: scale(1.01);
}

.trioptions button:hover::before {
  position: absolute;
  content: '';
  background-color: #957dff;
  width: 1.5vw;
  height: 1.5vw;
  border: 1px solid black;
  top: 32%;
  left: -35%;
  z-index: 10;
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
}

.trioptions img{
  position: absolute;
  transform: scalex(0.65) scaleY(0.8);
}

.trioptions a{
  z-index: 1;
  text-decoration: none;
  font-size: 4vh;
  padding-bottom: 0.6vh;
  line-height: 0;
  color: black;
  font-family: "NaikaiFont";
}

.StartScene .dialog_box{
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 11vh;
  width: 100%;
  height: 28vh;
}

.StartScene .dialog_box_pic{
  position: absolute;
  width: 90%;
  height: 35vh;
}

.StartScene .dialog_options_pic{
  position: absolute;
  width: 250%;
  height: 35vh;
}

.StartScene .dialog{
  position: absolute;
  left: 9vw;
  top: 11vh;
  font-size: 6vh;
  color: black;
  font-weight: normal;
  letter-spacing: 0.3vw;
  line-height: 0;
}

.dialog_box .skipButton,
.dialog_box .skipButton img{
  background: 0;
  z-index: 10;
  width: 4vw;
  height: 7vh;
}

.dialog_box .skipButton{
  position: absolute;
  right: 35vw;
  bottom: -2vh;
  font-size: 5vh;
  font-weight: normal;
  letter-spacing: 0.3vw;
  color: white;
  border: 0;
  cursor: pointer;
}

.dialog_box .skipButton:hover {
  transform-origin: center;
  transform: scale(1.03);
}

}

@media screen and (orientation: portrait) {
  /*開始介面*/
  .StartNameBox{
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);
    background-position: center;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 11;
  }

  .StartNameBox .inputBg{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35vw;
    height: 40vh;
    background-color: #9480b2;
  }

  .StartNameBox .inputBox{
    width: 18vw;
    height: 6vh;
    border: black solid 5px;
    margin: 10vh 0 5vh 0;
    padding-left: 1vw;
    color: #000;
    font-size: 2.4vh;
  }

  .StartNameBox .inputBox::placeholder{
    color: #999;
    font-size: 2.4vh;
  }

  .StartNameBox .inputButton{
    width: 18vw;
    height: 6vh;
    font-size: 4vh;
    font-weight: bold;
    color: #000;
    background-color: rgba(0,0,0,0);
    border: none;
  }

  .StartScene {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    z-index: 0;
  }

  .StartScene .blank{
    background-position: center;
    background-attachment: fixed;
    width: 100vw;
    height: 100vh;
    position: absolute;
  }

  .StartScene .bg{
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 0;
  }

  .StartScene .chara{
    position: absolute;
    width: 180%;
    top: 0;
    right: 0;
    z-index: 0;
  }

  .trioptions{
    position: absolute;
    width: 35vw;
    height: auto;
    top: 35vh;
    left: 16.5vw;
    z-index: 10;
    list-style-type: none;
    padding: 0vh 3vw;
  }

  .trioptions button{
    display: flex;
    position: relative;
    border: 0;
    justify-content: center;
    align-items: center;
    height: 5vh;
    width: 60vw;
    margin-bottom: 6vh;
    background: 0;
  }

  .trioptions button:hover{
    cursor: pointer;
    transform: scale(1.01);
  }

  .trioptions button:hover::before {
    position: absolute;
    content: '';
    background-color: #957dff;
    width: 1.5vw;
    height: 1.5vw;
    border: 1px solid black;
    top: 32%;
    left: -35%;
    z-index: 10;
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  }

  .trioptions img{
    position: absolute;
    transform: scalex(0.65) scaleY(0.7);
  }

  .trioptions a{
    z-index: 1;
    text-decoration: none;
    font-size: 3vh;
    padding-bottom: 0.6vh;
    line-height: 0;
    color: black;
    font-family: "NaikaiFont";
  }

  .StartScene .dialog_box{
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 11vh;
    width: 100%;
    height: 24vh;
  }

  .StartScene .dialog_box_pic{
    position: absolute;
    width: 95%;
    height: 32vh;
  }

  .StartScene .dialog_options_pic{
    position: absolute;
    width: 250%;
    height: 35vh;
  }

  .StartScene .dialog{
    position: absolute;
    left: 9vw;
    top: 8vh;
    font-size: 4vh;
    color: black;
    font-weight: normal;
    letter-spacing: 0.3vw;
    line-height: 1;
  }

  .dialog_box .skipButton,
  .dialog_box .skipButton img{
    background: 0;
    z-index: 10;
    width: 15vw;
    height: 6vh;
  }

  .dialog_box .skipButton{
    position: absolute;
    right: 12vw;
    bottom: -2vh;
    font-size: 5vh;
    font-weight: normal;
    letter-spacing: 0.3vw;
    color: white;
    border: 0;
    cursor: pointer;
  }

  .dialog_box .skipButton:hover {
    transform-origin: center;
    transform: scale(1.03);
  }

}

/*文字*/
.text nav{
  background-color: #333;
}

.text ul{
  list-style-type: none;
  margin: 0;
  padding-left: 10px;
  overflow: hidden;
}

.text li{
  margin-bottom: 10px;
}

.text a{
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/*導覽列*/
.navbar{
  background-color: rgba(0, 0, 0, 0);
  padding: 5px;
}

.navbar ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar li{
  margin-right: 50px;
}

.navbar a{
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 36px;
  font-family: '微軟正黑體', 'Arial', sans-serif;
  display: block;
}

.navbar a:hover{
  transform: scale(1.05);
  transform-origin: center;
  color: #e0e0e0;
  position: relative;
}

footer{
  position: fixed;
  bottom: -10px;
  left: 0;
  width: 100%;
  /*background-color: #333;*/
  font-size: 20px;
  color: white;
  padding: 0px 10px;
  text-align: center;
}