.myhome{
    color: #1e1e1e;
}

.myhome .openbook{
    position: absolute;
    top: 10vh;
    right: 5vw;
    width: 5vw;
    height: 10vh;
    border: 0.5vw solid black;
}

.myhome .bookview{
    position: absolute;
    top: 0vh;
    left: 0vw;
    transform: scaleX(0.95);
}

.statusbook{
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 99vh;
}

.statusbook .book {
    position: absolute;
    top: 44%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 125vw;
    height: 130vh;
}

/*.statusbook .fakebook {
    position: absolute;
    z-index: 1;
    top: 44%;
    left: 45%;
    transform: translate(10%, -43%) scaleX(1.05);
}*/

.statusbook .closebook{
    position: absolute;
    top: 7vh;
    left: 1vw;
    height: 6vh;
    width: 4vw;
    font-size: 1vw;
}

.statusbook .options{
    padding: 0;
    position: absolute;
    top: 7vh;
    left: 83vw;
    list-style-type: none;
}

.statusbook .options li{
    display: flex;
    margin-bottom: 5vh;
}

.statusbook .options button{
    position: relative;
    width: 15vw;
    height: 8vh;
    font-size: 3.8vh;
    cursor: pointer;
    background-color: rgb(52, 26, 0);
    border: 0;
    clip-path: polygon(22% 0%, 90% 0%, 80% 50%, 90% 100%, 22% 100%);
}

.statusbook .options div{
    display: flex;
    align-items: center;
    color: #d3c9b9;
    margin-left: 5vw;
    font-size: 1.6vw;
}

.bookdata{
    position: absolute;
    width: 75vw;
    height: 80vh;
    top: 9vh;
    left: 9vw;
    //transform: scale(0.65);
}

.bookcontainer{
    display: flex;
    width: 75vw;
    height: 85vh;
    top: 0.5vh;
}

.bookcontainer .fakebutton{
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    height: 0vh;
    cursor: pointer;
    font-size: 104%;
    font-weight: initial;
}

.bookcontainer .leftpart{
    flex: 1;
    margin-left: 1vw;
    position: relative;
}

.bookcontainer .rightpart{
    flex: 1;
    margin: 1vh 0 0 5vw;
    position: relative;
}

.bookcontainer .scrollbox{
    width: 36vw;
    height: 80vh;
    margin-top: 1vh;
    overflow: auto;
    scrollBehavior: smooth;
}

.bookcontainer .scrollbox::-webkit-scrollbar {
    width: 0.6vw;
}

.bookcontainer .scrollbox::-webkit-scrollbar-thumb {
    background-color: #4b2e0a;
    border-radius: 6px;
}

/*Homepage*/
.bookcontainer .chara{
    display: flex;
    color: black;
    margin-bottom: 3.5vh;
}

.bookcontainer .charaname{
    justify-content: center;
}

.bookcontainer .charaname button{
    font-weight: bold;
    font-size: 1.8vw;
    margin: 0 1.3vw;
    height: 5vh;
}

.bookcontainer .charadata{
    display: flex;
}

.bookcontainer .charadata img{
    flex: 1;
    width: 13vw;
    margin-left: 2vw;
    border: 1px solid black;
}

.bookcontainer .charadata .databar{
    flex: 1.5;
    display: flex;
    flex-direction: column;
    padding-top: 1.5vh;
    padding-left: 5vw;
}

.charadata .databar h{
    font-weight: bold;
    text-decoration: underline;
    font-size: 1.6vw;
    padding-left: 2vw;
    margin-bottom: 2vh;
}

.charadata .databar div{
    font-size: 1.3vw;
    padding-left: 0.7vw;
    margin-bottom: 2vh;
}

.bookcontainer .charaintro{
    height: 21vh;
    padding: 1vh 1vw;
    display: flex;
    flex-direction: column;
    border: 3px solid #4b2e0a;
    border-radius: 1vw;
}
.bookcontainer .charaintro h{
    margin-bottom: 0.5vh;
    font-size: 1.5vw;
    font-weight: bold;
}
.bookcontainer .charaintro div{
    font-size: 1.3vw;
    padding: 0 1vw;
}

.bookcontainer .detailtext{
    font-size: 1.7vw;
    line-height: 7vh;
    margin-right: 0vw;
}

/*Portfolio*/
.bookcontainer .portfolioTitle{
    font-size: 4vh;
    font-weight: bold;
    margin-top: 1vh;
    margin-bottom: 2vh;
    text-align: center;
    margin-right: 1.5vw;
}

.bookcontainer .portfolioText{
    font-size: 3.5vh;
    font-weight: bold;
}

.bookcontainer .list{
    width: 33vw;
    background-color: rgba(121, 121, 121, 0.62);
    margin-bottom: 2.5vh;
    padding-bottom: 1.5vh;
}

.bookcontainer .scrollbox button{
    margin: 1.5vh 1vw 1vh 1vw;
    font-size: 1.8vw;
    color: #231209;
    //text-decoration: underline;
}

.bookcontainer .eachline{
    font-size: 2vw;
    color: black;
    font-weight: bold;
    margin-bottom: 1vh;
}

.portfolioCircle{
    position: relative;
    width: 35vw;
    height: 40vw;
    margin-top: -1vh;
}

.portfolioCircle .circle{
    position: absolute;
    width: 10vw;
    height: 10vw;
    border: 0;
    font-size: 1.6vw;
    padding-bottom: 8vh;
    background-color: transparent;
    background-image: url(/public/pic/portfolio_icon/1.png);
    background-size: cover;
    cursor: pointer;
}

.portfolioCircle .circle:hover{
    transform: scale(1.05);
}

.portfolioCircle .logo{
    width: 12vw;
    position: absolute;
    top: 30vh;
    left: 12vw;
    opacity: 0.8;
}

.portfolioCircle .returnbutton{
    position: absolute;
    width: 5vw;
    height: 8vh;
    cursor: pointer;
    z-index: 10;
}

/*SenbaBlog*/
.senba_blog{
    width: 100%;
    display: flex;
    justify-content: center;
}
.senba_blog .blog_block{
    border: 1vw solid white;
    background-color: #333;
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 10vh 0;
    line-height: 3vh;
    padding: 3vh 3vw;
}

.senba_blog .blog_block img{
    width: 40%;
}

.top_blank{
    margin-top: 5vh;
}

.container {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: #333333;
}

.item {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar {
    display: none;
}
